import {ReactElement} from 'react';
import {GoodType, GoodTypeName, PlatformName, PlatformType} from '../../../types/goods';
import {Link} from 'react-router-dom';

import {ReactComponent as IconSale} from '../../../sprite/icon-sale.svg';
import {ReactComponent as IconPage} from '../../../sprite/icon-page.svg';
import {log} from 'util';

type PromoSlideProps = {
  title: string,
  subtitle: string,
  type: GoodType,
  genre: string,
  platform: PlatformType,
  desc: string,
  id: string,
  slideStep: number
  sale: number,
}

export default function PromoSlide({title, subtitle, type, genre, platform, desc, id, slideStep, sale}: PromoSlideProps): ReactElement  {
  return (
    <li className={`promo__item promo__item--${slideStep}`}>
      <div className="promo__content">
        <div className="promo__content-top space_bottom-m">
          <p className="title title_size--l page-title--cheap">
            {title}
          </p>

          <b className="cheap cheap_view--accent text_size--12 cheap_size--m">
            <IconSale className="icon" width={16} height={16}/>
            {`Скидка ${sale}%`}
          </b>
        </div>

        <p className="cheap cheap_view--primary text_size--12 space_bottom-l cheap_size--m" onKeyDown={(event) => console.log(event)}>
          <IconPage className="icon" width={16} height={16}/>
          <span>
            {subtitle}
          </span>
        </p>
        <ul className="description-details description-details--hide space_bottom-m text_size--14">
          <li>
            <dl>
              <dt className="">Тип</dt>
              <dd className="description-details__main">{GoodTypeName[type].name}</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>Жанр</dt>
              <dd>{genre}</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>Платформа</dt>
              <dd className="description-details__main">{PlatformName[platform]}</dd>
            </dl>
          </li>
        </ul>
        <p className="promo__desc text_size--14 text_line-height--150 space_bottom-l-l-l">
          {desc}
        </p>
        <Link
          className="promo__button button button_view--primary button_size--l"
          to={`/products/${id}`}
        >
          Узнать подробнее
        </Link>
      </div>
      <picture>
        <source
          srcSet="/img/promo-banner-1-mobile@1x.webp, /img/promo-banner-1-mobile@2x.webp 2x"
          type="image/webp"
        />
        <source
          srcSet="/img/promo-banner-1-mobile@1x.jpg, /img/promo-banner-1-mobile@2x.jpg 2x"
          type="image/jpeg"
        />
        <img
          className="promo__image radius--4"
          src="/img/promo-banner-1-mobile.jpg"
          alt=""
        />
      </picture>
    </li>
  );
}
