import {ReactElement} from 'react';
import {Link} from 'react-router-dom';
import {AppRoute} from '../../../const/common';

type BreadcrumbsProps = {
  firstPage: string;
  secondPage?: {
    name: string;
    url: string;
  }
  thirdPage?: {
    name: string;
    url: string;
  }
}

export default function Breadcrumbs({firstPage, secondPage, thirdPage}: BreadcrumbsProps): ReactElement {
  return (
    <div className="breadcrumbs__wrap">
      <ol
        itemScope={true}
        itemType="https://schema.org/BreadcrumbList"
        className="breadcrumbs container"
      >
        <li
          itemProp="itemListElement"
          itemScope={true}
          itemType="https://schema.org/ListItem"
          className="breadcrumbs__item"
        >
          <Link
            itemProp="item"
            to={AppRoute.Root}
            className="link link_view--primary link_view--hover-underline"
          >
            <span itemProp="name">Главная</span>
          </Link>
          <meta itemProp="position" content={'1'} />
        </li>
        {thirdPage && <li
          itemProp="itemListElement"
          itemScope={true}
          itemType="https://schema.org/ListItem"
          className="breadcrumbs__item"
        >
          <Link
            itemProp="item"
            to={thirdPage.url}
            className="link link_view--primary link_view--hover-underline"
          >
            <span itemProp="name">{thirdPage.name}</span>
          </Link>
          <meta itemProp="position" content={'2'} />
        </li>}
        {secondPage && <li
          itemProp="itemListElement"
          itemScope={true}
          itemType="https://schema.org/ListItem"
          className="breadcrumbs__item"
        >
          <Link
            itemProp="item"
            to={secondPage.url}
            className="link link_view--primary link_view--hover-underline"
          >
            <span itemProp="name">{secondPage.name}</span>
          </Link>
          <meta itemProp="position" content={'2'} />
        </li>}
        <li
          itemProp="itemListElement"
          itemScope={true}
          itemType="https://schema.org/ListItem"
          className="breadcrumbs__item"
        >
          <a
            itemProp="item"
            className="link link_view--primary link_view--hover-underline"
          >
            <span itemProp="name">{firstPage}</span>
          </a>
          <meta itemProp="position" content={secondPage ? '3' : '2'} />
        </li>
      </ol>
    </div>
  );
}
