import {createAction} from '@reduxjs/toolkit';
import {Good, GoodServer, GoodType, GraphicType, PlatformType} from '../types/goods';
import {FilterFields, Genre, Price} from '../types/filter';
import {SortingType} from '../types/sorting';
import {AppRoute, AuthStatus} from '../const/common';
import {UserData} from '../types/data';
import {ModalType} from '../types/modalType';
import {ArticleCardServer, ArticleServer} from '../types/blog';

export const loadGoods = createAction<GoodServer[]>('data/loadGoods');

export const addCartGood = createAction<Good>('data/addCartGood');

export const loadFilterFields = createAction<FilterFields>('data/loadFilterFields');

export const loadSearchGoods = createAction<GoodServer[]>('data/loadSearchGoods');

export const loadArticles = createAction<ArticleCardServer[]>('data/loadArticles');

export const setArticlesCount = createAction<number>('data/articlesCount');

export const loadArticle = createAction<ArticleServer>('data/loadArticle');

export const changeCurrentType = createAction<GoodType | null>('filter/changeCurrentType');

export const changeCurrentCategory = createAction<string | null>('filter/changeCurrentCategory');

export const toggleFilterGenre = createAction<Genre>('filter/addGenre');

export const toggleFilterGraphic = createAction<GraphicType>('filter/addGraphic');

export const toggleFilterPlatform = createAction<PlatformType>('filter/addPlatform');

export const resetCategories = createAction('filter/resetCategories');

export const resetFilter = createAction('filter/reset');

export const setPrice = createAction<Price>('filter/setPrice');

export const setSorting = createAction<SortingType>('sorting/setSorting');


export const showModal = createAction<ModalType>('modal/show');

export const hideModal = createAction('modal/hide');


export const redirectToRoute = createAction<AppRoute>('site/redirectToRoute');

export const requireAuth = createAction<AuthStatus>('user/requireAuth');

export const setUserData = createAction<Omit<UserData, 'api_token'> | null>('user/setUserData');

export const setPreventScroll = createAction<boolean>('page/setPreventScroll');

export const resetConsultationForm = createAction('consultation/reset');
