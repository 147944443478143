import React, {ReactElement, useEffect} from 'react';
import {FieldValues, RegisterOptions, useFormContext} from 'react-hook-form';
import cn from 'classnames';

type InputProps = {
  value?: string;
  formName: string,
  name: string,
  label: string,
  placeholder: string,
  type: string,
  validation?: RegisterOptions<FieldValues, string>,
}

export default function Input({type, formName, name, label, placeholder, validation, value}: InputProps): ReactElement {
  const {
    register,
    setValue,
    formState: {errors},
  } = useFormContext();

  useEffect(() => {
    setValue(name, value);
  }, [value]);

  const error = errors[name];

  const errorMessage = error ? error.message : '';
  const id = `${formName}-${name}`;

  return (
    <p className="input__wrapper input__wrapper--field text_view--tertiary space_bottom-l">
      <label
        className="input__description text_size--14 text_line-height--140"
        htmlFor={id}
      >
        {label}
      </label>
      <span className="field__wrapper">
        <input
          className={cn(
            'field',
            {'field_state--error': error}
          )}
          id={id}
          type={type}
          placeholder={placeholder}
          required={true}

          {...register(name, validation)}
        />
        {error && <b className="field__message">
          {String(errorMessage)}
        </b>}
      </span>
    </p>
  );
}
