import {ReactElement} from 'react';
import { ReactComponent as LogoImage } from '../../../sprite/logo.svg';
import cn from 'classnames';
import {Link} from 'react-router-dom';
import {AppRoute} from '../../../const/common';

type LogoProps = {
  isLight?: boolean;
}

function Logo({isLight}: LogoProps): ReactElement {
  return (
    <Link
      to={AppRoute.Root}
      className={cn(
        'logo',
        'link',
        'link_view--primary',
        {'main-footer__logo': isLight},
        {'main-header__logo': !isLight},
      )}
    >
      <span className="visuallyhidden">Логотип</span>
      <LogoImage width={125} height={32}/>
    </Link>
  );
}

export default Logo;
