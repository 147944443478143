import React, {ReactElement} from 'react';

type FormCloseButtonProps = {
  onClick: () => void;
}

export default function FormCloseButton({onClick}: FormCloseButtonProps): ReactElement {
  return (
    <button
      className="button button_view--simple modal__close"
      type="button"
      onClick={onClick}
    >
      <span className="visuallyhidden">Закрыть</span>
    </button>
  );
}
