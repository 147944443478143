const AUTH_TOKEN_KEY_NAME = 'it-tz-token';
const RESET_TOKEN_KEY_NAME = 'it-tz-token-reset';
const RESET_EMAIL_KEY_NAME = 'it-tz-email-reset';

export type Token = string;

export const getToken = (): Token => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY_NAME);
  return token ?? '';
};

export const saveToken = (token: Token): void => {
  localStorage.setItem(AUTH_TOKEN_KEY_NAME, token);
};

export const dropToken = (): void => {
  localStorage.removeItem(AUTH_TOKEN_KEY_NAME);
};

export const getResetToken = (): Token => {
  const token = localStorage.getItem(RESET_TOKEN_KEY_NAME);
  return token ?? '';
};

export const saveResetToken = (token: Token): void => {
  localStorage.setItem(RESET_TOKEN_KEY_NAME, token);
};

export const dropResetToken = (): void => {
  localStorage.removeItem(RESET_TOKEN_KEY_NAME);
};


export const getResetEmail = (): Token => {
  const token = localStorage.getItem(RESET_EMAIL_KEY_NAME);
  return token ?? '';
};

export const saveResetEmail= (token: Token): void => {
  localStorage.setItem(RESET_EMAIL_KEY_NAME, token);
};

export const dropResetEmail = (): void => {
  localStorage.removeItem(RESET_EMAIL_KEY_NAME);
};
