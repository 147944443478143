import React, {ReactElement} from 'react';
import Header from '../../components/page/header/header';
import Breadcrumbs from '../../components/page/breadcrumbs/breadcrumbs';
import Footer from '../../components/page/footer/footer';
import ModalContainer from '../../components/page/modal-container/modal-container';
import About from '../../components/about/about';
import {CanonicalUrl, PageDesc, PageTitle} from '../../const/seo';
import Seo from '../../components/service/seo/seo';

export default function AboutScreen(): ReactElement {
  return (
    <>
      <Seo
        title={PageTitle.About}
        desc={PageDesc.About}
        url={CanonicalUrl.About}
      />

      <Header />
      <About/>
      <Footer/>
      <ModalContainer />
    </>
  )
}
