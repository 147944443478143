import React, {ReactElement} from 'react';
import {useAppDispatch} from '../../../../hooks';
import {toggleFilterGraphic} from '../../../../store/actions';
import { ReactComponent as IconImage } from '../../../../sprite/icon-image.svg';
import {GraphicName, GraphicType} from '../../../../types/goods';

type CheapGraphicProps = {
  graphic: GraphicType;
}

export default function CheapGraphic({graphic}: CheapGraphicProps): ReactElement {
  const dispatch = useAppDispatch();

  return (
    <li
      onClick={() => dispatch(toggleFilterGraphic(graphic))}
    >
      <p
        className="cheap cheap_view--accent-tertiary cheap_view--accent-tertiary-hover text_size--12 cheap_size--s"
      >
        <IconImage className="icon" width={16} height={16}/>
        <span>{GraphicName[graphic]}</span>
      </p>
    </li>
  );
}
