import {Good, GoodType} from '../types/goods';

type goodsCategoryCount = {
  [key in GoodType]: categoryProps;
}

type categoryProps = {
  [slug: string]: string,
};

export const getCategoryList = (goods: Good[]): goodsCategoryCount => {
  const goodsCategory: goodsCategoryCount = {
    [GoodType.Game]: {},
    [GoodType.Site]: {},
    [GoodType.App]: {},
    [GoodType.Design]: {},
    [GoodType.Cms]: {},
    [GoodType.Software]: {},
  };

  goods.forEach((good) => {
    if (good.category) {
      goodsCategory[good.type][good.category.slug] = good.category.name
    }
  });

  return goodsCategory;
}
