import React, {ReactElement} from 'react';
import Details from '../atomas/details/details';

export default function Faq(): ReactElement {
  return (
    <main className="profile__main-page">
      <h1 className="search__title title title_size--l container">Часто задаваемые вопросы</h1>

      <section className="grid grid_gap--xxs-xs-xs container space_bottom-5xl">
        <Details
          title={'Что такое ТЗ (техническое задание) и для чего оно нужно?'}
          isOpen={true}
        >
          <p>
            ТЗ - это документ, который содержит подробное описание требований к разрабатываемому проекту в IT сфере. Он нужен для четкого понимания задачи и ее реализации специалистами.
          </p>
        </Details>

        <Details
          title={'Какие типы ТЗ доступны в вашем магазине?'}
        >
          <p>
            Мы предлагаем разнообразные типы ТЗ, включая разработку веб-сайтов, мобильных приложений, игр, программного обеспечения и т. д.
          </p>
        </Details>

        <Details
          title={'Какие преимущества покупки готового ТЗ?'}
        >
          <p>
            Покупка готового ТЗ позволяет экономить время и ресурсы на разработку с нуля, получить проверенные решения и ускорить запуск проекта.
          </p>
        </Details>

        <Details
          title={'Как происходит оплата и доставка ТЗ после покупки?'}
        >
          <p>
            Оплата происходит онлайн через удобные способы, после чего ТЗ моментально доступно для скачивания в личном кабинете нашего сайта.
          </p>
        </Details>

        <Details
          title={'Могу ли я вернуть или обменять купленное ТЗ?'}
        >
          <p>
            Возврат или обмен купленного ТЗ возможен только в случае выявления фактических ошибок или несоответствия описанию.
          </p>
        </Details>

        <Details
          title={'Какой формат файлов доступен для скачивания ТЗ?'}
        >
          <p>
            Мы предоставляем ТЗ в форматах, удобных для работы с ними, например, DOCX, PDF, XLSX и другие, в зависимости от типа ТЗ.
          </p>
        </Details>

        <Details
          title={'Что делать, если у меня возникли вопросы по содержанию ТЗ или его использованию?'}
        >
          <p>
            Вы всегда можете обратиться к нашей поддержке с любыми вопросами. Мы готовы помочь и дать необходимые консультации.
          </p>
        </Details>

        <Details
          title={'Какие гарантии качества предоставляются на покупку готовых ТЗ?'}
        >
          <p>
            Мы гарантируем высокое качество и соответствие ТЗ заявленным требованиям. При выявлении проблем готовы предоставить техническую поддержку.
          </p>
        </Details>

        <Details
          title={'Могу ли я заказать индивидуальное ТЗ, не представленное в вашем магазине?'}
        >
          <p>
            Да, вы можете обратиться к нам с запросом на разработку индивидуального ТЗ. Мы готовы рассмотреть вашу задачу и предложить решение.
          </p>
        </Details>

        <Details
          title={'Какие отзывы имеются о ваших ТЗ от других клиентов?'}
        >
          <p>
            Мы имеем множество положительных отзывов от клиентов, которые оценивают качество и удобство наших ТЗ для своих проектов.
          </p>
        </Details>
      </section>
    </main>
  );
}
