import React, {ReactElement} from 'react';
import PurchaseHistoryList from './purchase-history-list/purchase-history-list';

export default function PurchaseHistory(): ReactElement {
  return (
    <main className="purchase-history__main-page">
      <h1 className="purchase-history__title title title_size--l container">Профиль</h1>

      <PurchaseHistoryList/>
    </main>
  )
}
