import {Good, GoodType, PurchasedGoods} from '../types/goods';
import {createReducer} from '@reduxjs/toolkit';
import {
  toggleFilterGenre,
  toggleFilterGraphic,
  toggleFilterPlatform,
  changeCurrentType,
  loadGoods,
  setPrice,
  resetCategories,
  setSorting,
  requireAuth,
  setUserData,
  showModal,
  hideModal,
  resetFilter,
  setPreventScroll,
  loadArticles, setArticlesCount, loadArticle, loadSearchGoods, changeCurrentCategory, loadFilterFields, addCartGood,
  resetConsultationForm
} from './actions';
import {Filter, FilterFields} from '../types/filter';
import {toggleArrayItem, toggleGenresArrayItem} from '../utils/array';
import {SortingType} from '../types/sorting';
import {AuthStatus, RANGE_STEP} from '../const/common';
import {UserData} from '../types/data';
import {ModalType} from '../types/modalType';
import {getMaxPrice, getMinPrice} from '../utils/filter';
import {parseArticleCardDataFromServer, parseArticleDataFromServer, parseGoodsDataFromServer} from '../utils/adapter';
import {Article, ArticleCard} from '../types/blog';

type initialState = {
  goods: Good[];
  purchasedGoods: PurchasedGoods[];
  cartGoods: Good[];

  currentType: GoodType | null;
  currentCategory: string | null,
  filter: Filter;
  sorting: SortingType;
  authStatus: AuthStatus;
  user: Omit<UserData, 'api_token'> | null;
  modal: ModalType | null;

  articleCards: ArticleCard[];
  articlesCount: number,
  article: Article | null,

  searchGoods: Good[];

  isPreventScroll: boolean;

  filterFields: FilterFields;
}

const initialState: initialState = {
  goods: [],
  purchasedGoods: [],
  cartGoods: [],

  currentType: null,
  currentCategory: null,

  filter: {
    genres: [],
    graphics: [],
    platforms: [],

    price: [0, 999999],
    priceRange: [0, 999999],
  },

  articleCards: [],
  articlesCount: 0,
  article: null,

  searchGoods: [],

  sorting: SortingType.Popular,

  authStatus: AuthStatus.Unknown,

  user: null,

  modal: null,

  isPreventScroll: false,

  filterFields: {
    genres: [],
    graphics: [],
    platforms: [],
    stacks: [],
    compositions: [],
    types: [],
    categories: [],
  }
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loadGoods, (state, action) => {
      state.goods = action.payload.map((good, id) => parseGoodsDataFromServer(good, id));
      const minPrice = getMinPrice(state.goods);
      const maxPrice = getMaxPrice(state.goods);

      const floorMinPrice = Math.floor(minPrice / RANGE_STEP) * RANGE_STEP;
      const floorMaxPrice = (Math.floor(maxPrice / RANGE_STEP) + 1) * RANGE_STEP;

      state.filter.priceRange = [floorMinPrice, floorMaxPrice];
      state.filter.price = [floorMinPrice, floorMaxPrice];
    })
    .addCase(addCartGood, (state, action) => {
      state.cartGoods.push(action.payload);
    })
    .addCase(loadFilterFields, (state, action) => {
      state.filterFields = action.payload;
    })
    .addCase(loadSearchGoods, (state, action) => {
      state.searchGoods = action.payload.map((good, id) => parseGoodsDataFromServer(good, id));
    })
    .addCase(loadArticles, (state, action) => {
      state.articleCards = action.payload.map((article) => parseArticleCardDataFromServer(article));
    })
    .addCase(loadArticle, (state, action) => {
      state.article = parseArticleDataFromServer(action.payload);
    })
    .addCase(setArticlesCount, (state, action) => {
      state.articlesCount = action.payload;
    })
    .addCase(changeCurrentType, (state, action) => {
      state.currentType = action.payload;

      state.filter.genres = initialState.filter.genres;
      state.filter.graphics = initialState.filter.graphics;
      state.filter.platforms = initialState.filter.platforms;
      state.currentCategory = initialState.currentCategory;
    })
    .addCase(changeCurrentCategory, (state, action) => {
      state.currentCategory = action.payload;

      state.filter.genres = initialState.filter.genres;
      state.filter.graphics = initialState.filter.graphics;
      state.filter.platforms = initialState.filter.platforms;
    })
    .addCase(resetCategories, (state) => {
      state.filter.genres = initialState.filter.genres;
      state.filter.graphics = initialState.filter.graphics;
      state.filter.platforms = initialState.filter.platforms;
    })
    .addCase(resetFilter, (state) => {
      state.filter.genres = initialState.filter.genres;
      state.filter.graphics = initialState.filter.graphics;
      state.filter.platforms = initialState.filter.platforms;

      state.filter.price = state.filter.priceRange;
    })
    .addCase(toggleFilterGenre, (state, action) => {
      state.filter.genres = toggleGenresArrayItem(state.filter.genres, action.payload);
    })
    .addCase(toggleFilterGraphic, (state, action) => {
      state.filter.graphics = toggleArrayItem(state.filter.graphics, action.payload);
    })
    .addCase(toggleFilterPlatform, (state, action) => {
      state.filter.platforms = toggleArrayItem(state.filter.platforms, action.payload);
    })
    .addCase(setPrice, (state, action) => {
      state.filter.price = action.payload;
    })
    .addCase(setSorting, (state, action) => {
      state.sorting = action.payload;
    })
    .addCase(requireAuth, (state, action) => {
      state.authStatus = action.payload;
    })
    .addCase(setUserData, (state, action) => {
      state.user = action.payload;
    })
    .addCase(showModal, (state, action) => {
      state.modal = action.payload;
    })
    .addCase(hideModal, (state) => {
      state.modal = null;
    })
    .addCase(setPreventScroll, (state, action) => {
      state.isPreventScroll = action.payload;
    });
});

export {reducer};
