import React, {ReactElement} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import FormTitle from '../../atomas/form-title/form-title';
import Input from '../../atomas/input/input';
import {ValidationConfig} from '../../../const/validation-config';
import FormSubmitButton from '../../atomas/form-submit-button/form-submit-button';
import FormCloseButton from '../../atomas/form-close-button/form-close-button';
import {hideModal} from '../../../store/actions';
import Modal from '../modal';
import {SendCodeData} from '../../../types/data';
import {useAppDispatch} from '../../../hooks';
import {resetAction} from '../../../store/api-actions';

const FORM_NAME = 'send-code';

export default function ModalSendCode(): ReactElement {
  const methods = useForm<SendCodeData>();
  const dispatch = useAppDispatch();

  const onSubmit = methods.handleSubmit(data => {
    dispatch(resetAction(data));
  });

  return (
    <Modal>
      <FormProvider {...methods}>
        <form
          className="modal__inner"
          spellCheck="false"
          noValidate
          onSubmit={event => event.preventDefault()}
        >
          <FormTitle>Восстановление доступа</FormTitle>

          <p
            className="input__wrapper input__wrapper--field text_view--tertiary space_bottom-l text_size--14 modal__desc">
            Введите ваш e-mail, мы пришлем вам код подтверждения
          </p>

          <Input
            formName={FORM_NAME}
            name={'email'}
            label={'Email'}
            placeholder={'Введите Email'}
            type={'email'}
            validation={ValidationConfig.email}
          />

          <FormSubmitButton onSubmit={onSubmit} className={'modal__bottom'}>
            Восстановить
          </FormSubmitButton>

          <FormCloseButton onClick={() => dispatch(hideModal())}/>
        </form>
      </FormProvider>
    </Modal>
  );
}
