import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
import 'dayjs/locale/ru'

dayjs.extend(updateLocale)
dayjs.updateLocale('ru', {
  months: [
    "января", "февраля", "марта", "апреля", "мая", "июня", "июля",
    "августа", "сентября", "октября", "ноября", "декабря"
  ]
})

export enum DateType {
  Dots = 'DD.MM.YYYY',
  MonthName = 'DD MMMM YYYY',
}

export const parseDate = (date: string, dateType = DateType.Dots) => {
  dayjs.locale('ru');
  return dayjs(date).format(dateType);
}
