import {ReactElement} from 'react';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import { ReactComponent as IconCloseBold } from '../../../sprite/icon-close-bold.svg';
import {resetCategories, toggleFilterGenre, toggleFilterGraphic, toggleFilterPlatform} from '../../../store/actions';
import {GraphicName, PlatformName} from '../../../types/goods';

export default function ActiveFilterCategories(): ReactElement {
  const dispatch = useAppDispatch();

  const filter = useAppSelector((state) => state.filter);

  const genres = filter.genres;
  const graphics = filter.graphics;
  const platforms = filter.platforms;

  return (
    <ul className="catalog__active-filter">
      <li className="catalog__active-filter-item">
        <button
          className="button button_view--primary cheap cheap_size--m text_size--12 catalog__active-filter-reset"
          onClick={() => dispatch(resetCategories())}
        >
          Очистить все
        </button>
      </li>
      {genres.map((genre, index) => (
        <li
          className="catalog__active-filter-item"
          key={`genre-${index}`}
        >
          <span className="cheap cheap_view--accent-tertiary cheap_size--m text_size--12">
            {genre.name}
            <button
              className="catalog__active-filter-close button button_view--simple"
              onClick={() => dispatch(toggleFilterGenre(genre))}
            >
              <IconCloseBold className="icon icon_color--accent" width={16} height={16}/>
            </button>
          </span>
        </li>
      ))}
      {graphics.map((graphic, index) => (
        <li
          className="catalog__active-filter-item"
          key={`graphic-${index}`}
        >
          <span className="cheap cheap_view--accent-tertiary cheap_size--m text_size--12">
            {GraphicName[graphic]}
            <button
              className="catalog__active-filter-close button button_view--simple"
              onClick={() => dispatch(toggleFilterGraphic(graphic))}
            >
              <IconCloseBold className="icon icon_color--accent" width={16} height={16}/>
            </button>
          </span>
        </li>
      ))}
      {platforms.map((platform, index) => (
        <li
          className="catalog__active-filter-item"
          key={`platform-${index}`}
        >
          <span className="cheap cheap_view--accent-tertiary cheap_size--m text_size--12">
            {PlatformName[platform]}
            <button
              className="catalog__active-filter-close button button_view--simple"
              onClick={() => dispatch(toggleFilterPlatform(platform))}
            >
              <IconCloseBold className="icon icon_color--accent" width={16} height={16}/>
            </button>
          </span>
        </li>
      ))}
    </ul>
  );
}
