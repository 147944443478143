import {ReactElement} from 'react';
import {Link} from 'react-router-dom';
import {ReactComponent as IconWeb} from '../../../sprite/icon-web.svg';
import {ReactComponent as IconGame} from '../../../sprite/icon-game.svg';
import {ReactComponent as IconSmartphone} from '../../../sprite/icon-smartphone.svg';
import {AppRoute} from '../../../const/common';
import {changeCurrentType} from '../../../store/actions';
import {ScrollPosition, scrollTop} from '../../../utils/common';
import {useAppDispatch} from '../../../hooks';
import {GoodType} from '../../../types/goods';

export default function TopCategory(): ReactElement {
  const dispatch = useAppDispatch();

  return (
    <>
      <section className="top-category space_bottom-xxl">
        <h1 className="top-category__title title title_size--m space_bottom-l">
          Готовые технические задания для разработки IT проектов и ТЗ на создание сайтов
        </h1>

        <p className="top-category__desc text_size--14 text_line-height--150 space_bottom-l">
          Техническое задание (ТЗ) — это один из ключевых документов в любом IT проекте. Оно играет роль дорожной карты, по которой команда разработчиков движется к конечной цели. Техническое задание помогает четко определить требования заказчика, структурировать процесс разработки и минимизировать риски, связанные с недопониманием или изменением требований в ходе работы. Также оно позволяет сэкономить время и ресурсы.

          <br/>
          <br/>

          Четко сформулированные требования позволяют избежать переработок и исправлений, которые часто возникают из-за недопонимания между заказчиком и исполнителем. Кроме того, наличие готового технического задания облегчает планирование проекта, позволяет более точно оценить сроки и стоимость работ.

          <br/>
          <br/>

          ТЗ также служит юридическим документом, который защищает интересы обеих сторон при создании IT-продукта. В случае возникновения споров или разногласий, оно выступает в роли арбитра и позволяет разрешить конфликт на основании первоначально согласованных условий.
        </p>

        <hr className="top-category__line space_bottom-l"/>

        <ul className="top-category__list grid grid_col--3 grid_gap--s-m-m">
          <li className="top-category__item">
            <Link
              className="top-category__link link_view--primary link_color--primary"
              to={`${AppRoute.Catalog}tz-na-razrabotku-igr`}
              onClick={() => {
                dispatch(changeCurrentType(GoodType.Game));
                scrollTop(ScrollPosition.Top);
              }}
            >
              <IconGame className="icon icon_color--accent" width={34} height={34}/>
              ТЗ на разработку игры
            </Link>
          </li>
          <li className="top-category__item">
            <Link
              className="top-category__link link_view--primary link_color--primary"
              to={`${AppRoute.Catalog}tz-na-razrabotku-mobilnogo-prilozheniya`}
              onClick={() => {
                dispatch(changeCurrentType(GoodType.App));
                scrollTop(ScrollPosition.Top);
              }}
            >
              <IconSmartphone className="icon icon_color--accent" width={34} height={34}/>
              ТЗ на разработку приложения
            </Link>
          </li>
          <li className="top-category__item">
            <Link
              className="top-category__link link_view--primary link_color--primary"
              to={`${AppRoute.Catalog}tz-na-razrabotku-sajta`}
              onClick={() => {
                dispatch(changeCurrentType(GoodType.Site));
                scrollTop(ScrollPosition.Top);
              }}
            >
              <IconWeb className="icon icon_color--accent" width={34} height={34}/>
              ТЗ на разработку сайта
            </Link>
          </li>
        </ul>
      </section>
    </>
  )
}
