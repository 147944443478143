import React, {ReactElement, useEffect, useState} from 'react';
import CatalogSorting from './catalog-sorting/catalog-sorting';
import Filter from '../filter/filter';
import {useAppSelector} from '../../hooks';
import ActiveFilterCategories from '../filter/filter-active-categories/active-filter-categories';
import cn from 'classnames';
import CatalogList from './catalog-list/catalog-list';
import {ReactComponent as IconFilter} from '../../sprite/icon-filter.svg';
import {ReactComponent as IconFolder} from '../../sprite/icon-folder.svg';
import Category from '../category/category';
import {useParams} from 'react-router-dom';
import Breadcrumbs from '../page/breadcrumbs/breadcrumbs';
import {CanonicalUrl} from '../../const/seo';
import {AppRoute} from '../../const/common';
import {filterGoods} from '../../utils/filter';
import Seo from '../service/seo/seo';

export default function Catalog(): ReactElement {
  const params = useParams();

  const goods = useAppSelector((state) => state.goods);
  const filter = useAppSelector((state) => state.filter);
  const type = useAppSelector((state) => state.currentType);
  const category = useAppSelector((state) => state.currentCategory);

  const genres = filter.genres;
  const graphics = filter.graphics;
  const platforms = filter.platforms;

  const isActiveFilterCategoriesShow = !(
    genres.length === 0 &&
    graphics.length === 0 &&
    platforms.length === 0
  );

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);

  const [pageName, setPageName] = useState('Каталог');
  const [typeName, setTypeName] = useState('');
  const [categoryName, setCategoryName] = useState('');

  const [filteredGoods, setFilteredGoods] = useState(
    filterGoods(goods, filter, type, category)
  );

  const categoryData = useAppSelector((state) => state.filterFields);

  useEffect(() => {
    if (params.category) {
      const currentCategoryData = categoryData.categories.find((category) => {
        return category.slug === params.category;
      })
      currentCategoryData && setCategoryName(currentCategoryData.name);
    } else {
      setCategoryName('');
    }

    if (params.type) {
      const currentTypeData = categoryData.types.find((type) => {
        return type.slug === params.type;
      })
      currentTypeData && setTypeName(currentTypeData.name);
    } else {
      setTypeName('');
    }
  }, [params]);

  useEffect(() => {
    if (categoryName) {
      setPageName(categoryName);
    } else if (typeName) {
      setPageName(typeName);
    } else {
      setPageName('Каталог');
    }
  }, [categoryName, typeName]);

  useEffect(() => {
    setFilteredGoods(
      filterGoods(goods, filter, type, category)
    );
  }, [categoryName, typeName, filter]);

  return (
    <>
      <Seo
        title={`Купить готовые ${pageName === 'Каталог' ? 'ТЗ' : pageName} - низкие цены | TaskStore"`}
        desc={`Продажа готовых ${pageName === 'Каталог' ? 'ТЗ' : pageName}! У нас самый большой выбор индивидуальных технических заданий по выгодным ценам. Ускорьте процесс с помощью недорогих готовых шаблонов в интернет-магазине TaskStore!"`}
        url={CanonicalUrl.Main}
      />

      <Breadcrumbs
        thirdPage={{
          name: 'Каталог',
          url: AppRoute.Catalog,
        }}
        secondPage={categoryName ? {
          name: typeName,
          url: `${AppRoute.Catalog}${params.type}`
        } : undefined}
        firstPage={categoryName ? categoryName : typeName}
      />
      <section
        className={cn(
          'catalog',
          'container',
          {'catalog--active-filter': isActiveFilterCategoriesShow},
          {'catalog--filter-open': isFilterOpen},
          {'catalog--category-open': isCategoryOpen},
        )}
      >
        <h2 className="catalog__title title title_size--m">
          {pageName}
        </h2>
        <div className="catalog__inner">
          {/*<CatalogTabs/>*/}

          <div className="catalog__filter-open-wrapper catalog__filter-open-wrapper--catalog">
            <button
              className="catalog__filter-open button text_size--14"
              type="button"
              onClick={() => setIsCategoryOpen((prevState) => !prevState)}
            >
              <IconFolder className="icon" width={24} height={24}/>
              Каталог
            </button>
          </div>

          <div className="catalog__filter-open-wrapper">
            <button
              className="catalog__filter-open button text_size--14"
              type="button"
              onClick={() => setIsFilterOpen((prevState) => !prevState)}
            >
              <IconFilter className="icon" width={24} height={24}/>
              Фильтр
            </button>
          </div>

          <div className="filter__wrapper">
            <Category
              closeCategoryHandler={() => setIsCategoryOpen(false)}
            />

            <Filter
              closeFilterHandler={() => setIsFilterOpen(false)}
              filteredGoods={filteredGoods}
            />
          </div>

          <CatalogSorting/>
          <ActiveFilterCategories/>
          <CatalogList
            filteredGoods={filteredGoods}
          />
        </div>
      </section>
    </>
  )
}
