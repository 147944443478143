import {ReactElement, useState} from 'react';
import {ReactComponent as IconClose} from '../../sprite/icon-close.svg';
import {ReactComponent as IconArrowBottom} from '../../sprite/icon-arrow-bottom.svg';
import {useAppDispatch, useAppSelector} from '../../hooks';
import FilterGenres from './filter-genres/filter-genres';
import FilterTypes from './filter-types/filter-types';
import {Good, GoodType} from '../../types/goods';
import FilterPrice from './filter-price/filter-price';
import FilterGraphics from './filter-graphics/filter-graphics';
import FilterPlatform from './filter-platform/filter-platform';
import cn from 'classnames';
import {resetFilter} from '../../store/actions';

type FilterProps = {
  closeFilterHandler: () => void;
  filteredGoods: Good[];
};

export default function Filter({closeFilterHandler, filteredGoods}: FilterProps): ReactElement {
  const dispatch = useAppDispatch();
  const currentType = useAppSelector((state) => state.currentType);

  const [isGenresOpen, setIsGenresOpen] = useState(true);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  return (
    <form
      className={cn(
        'catalog__filter',
        'filter',
        {'filter--close': !isFilterOpen}
      )}
    >
      <div className="filter__inner radius--16 border_view--primary">
        <div className="filter__top">
          <h3 className="filter__title title title_size--s">Фильтр</h3>

          <button
            className="button button_view--simple"
            type="button"
            onClick={() => setIsFilterOpen((prevState) => !prevState)}
          >
            <IconArrowBottom
              className="filter__top-toggle icon"
              width={24}
              height={24}
            />
            <span className="visuallyhidden">Свернуть</span>
          </button>

          <button
            className="button button_view--simple filter__reset text_size--14 text_view--accent"
            type="reset"
            onClick={() => dispatch(resetFilter())}
          >
            Отчистить
          </button>
          <button
            className="button button_view--simple filter__close"
            type="button"
            onClick={closeFilterHandler}
          >
            <IconClose className="icon" width={24} height={24}/>
            <span className="visuallyhidden">Закрыть</span>
          </button>
        </div>

        <fieldset className="filter__fieldset filter__fieldset-range">
          <legend className="text_size--16 text_line-height--150 text_weight--500 text_view--base space_bottom-l">
            Цена
          </legend>
          <FilterPrice/>
        </fieldset>

        {/*<fieldset className="filter__fieldset filter__fieldset-type">*/}
        {/*  <legend*/}
        {/*    className="text_size--16 text_line-height--150 text_weight--500 text_view--base space_bottom-m">Тип*/}
        {/*  </legend>*/}

        {/*  <FilterTypes/>*/}
        {/*</fieldset>*/}

        {(currentType === GoodType.Game || currentType === GoodType.App) && <fieldset
          className={cn(
            'filter__fieldset',
            'filter__fieldset--toggle',
            'filter__fieldset-category-game',
            {
              'filter__fieldset--open': isGenresOpen,
              'filter__fieldset--close': !isGenresOpen
            }
          )}
        >
          <legend
            className="text_size--16 text_line-height--150 text_weight--500 text_view--base space_bottom-m"
          >
            {currentType === GoodType.Game ? 'Жанр' : 'Вид'}

            <button
              className="button button_view--simple"
              type="button"
              onClick={() => setIsGenresOpen((prevState) => !prevState)}
            >
              <IconArrowBottom className="icon" width={24} height={24}/>
              <span className="visuallyhidden">Свернуть</span>
            </button>
          </legend>
          <div className="filter__fieldset-inner">
            <FilterGenres/>
          </div>
        </fieldset>}

        {(currentType === GoodType.Game || currentType === GoodType.Site) && <fieldset
          className="filter__fieldset filter__fieldset-graphic"
        >
          <legend className="text_size--16 text_line-height--150 text_weight--500 text_view--base space_bottom-m">
            Графика
          </legend>

          <FilterGraphics/>
        </fieldset>}

        {currentType === GoodType.Game && <fieldset
          className="filter__fieldset filter__fieldset-platform"
        >
          <legend className="text_size--16 text_line-height--150 text_weight--500 text_view--base space_bottom-m">
            Платформа
          </legend>

          <FilterPlatform
            filteredGoods={filteredGoods}
          />
        </fieldset>}

      </div>
    </form>
  );
}
