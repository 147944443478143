import React, {ReactElement} from 'react';

type FormTitleProps = {
  children: string;
}

export default function FormTitle({children}: FormTitleProps): ReactElement {
  return (
    <h2 className="title title_size--s space_bottom-l modal__title">
      {children}
    </h2>
  );
}
