export enum GoodType {
  Game = 'GAME',
  App = 'APP',
  Site = 'SITE',
  Design = 'DESIGN',
  Cms = 'CMS',
  Software = 'SOFTWARE',
}

type GoodTypeServer = {
  id: number,
  name: string,
  slug: string,
}

type GoodCategoryServer = {
  id: number,
  name: string,
  slug: string,
}

export const GoodTypeList = [
  GoodType.Game,
  GoodType.Software,
  GoodType.Site,
  GoodType.Design,
  GoodType.Cms,
  GoodType.App,
];

type GoodGenreServer = {
  id: number,
  name: string,
}

type GoodStackServer = {
  id: number,
  name: string,
}

export const GoodTypeName = {
  GAME: {
    name: 'ТЗ на разработку игр',
    slug: 'tz-na-razrabotku-igr',
  },
  SITE: {
    name: 'ТЗ на разработку сайта',
    slug: 'tz-na-razrabotku-sajta',
  },
  APP: {
    name: 'ТЗ на разработку мобильных приложений',
    slug: 'tz-na-razrabotku-mobilnogo-prilozheniya',
  },
  SOFTWARE: {
    name: 'ТЗ на разработку ПО',
    slug: 'tz-na-razrabotku-po',
  },
  DESIGN: {
    name: 'ТЗ на разработку дизайна',
    slug: 'tz-na-razrabotku-dizajna',
  },
  CMS: {
    name: 'ТЗ на внедрение CMS',
    slug: 'tz-na-vnedrenie-cms',
  },
}

export enum CheapType {
  Graphic = 'GRAPHIC',
  Genre = 'GENRE',
  Platform = 'PLATFORM',
}

export enum GraphicType {
  ThreeD = 'THREE_D',
  TwoD = 'TWO_D',
}

type GraphicTypeServer = {
  id: number,
  name: string,
}

export const GraphicTypeList = [
  GraphicType.ThreeD,
  GraphicType.TwoD,
];

export enum GraphicName {
  THREE_D = '3D',
  TWO_D = '2D',
}

export enum PlatformType {
  Mobile = 'MOBILE',
  Web = 'WEB',
  Computer = 'COMPUTER',
}

type PlatformTypeServer = {
  id: number,
  name: string,
}

type ContentListServer = {
  id: number,
  name: string,
}

export const PlatformTypeList = [
  PlatformType.Mobile,
  PlatformType.Web,
  PlatformType.Computer,
];

export enum PlatformName {
  MOBILE = 'Andriod/IOS',
  WEB = 'Web',
  COMPUTER = 'PC',
}

export type Image = {
  src: string,
  alt: string,
}

export type ImageServer = {
  file_name: string,
  size: number,
  url: string,
  mime_type: string,
}

export type Good = {
  id: string,

  type: GoodType,
  typeSlug: string,
  category: GoodCategoryServer,

  desc: string,
  fullDesc: string,
  images: Image[],

  view: number,
  purchases: number,

  genre: string,
  nameGenre: string,
  fullGenre: string,
  platform?: PlatformType,
  graphic?: GraphicType,

  price: number,
  oldPrice: number,
  priceDev: number,

  date: string,

  stack: string[],
  style?: string,

  isEstimate: boolean,
  isAdminPanel: boolean,

  contentList: string[],
  analog: string[],
  pages: number,
};

export type GoodServer = {
  id: number,
  name: string,
  price: number,
  old_price: number,
  description: string,
  pages: number,
  development_cost: number,
  admin_panel: boolean,
  estimate: boolean,
  similar: string,
  published_at: string,
  views: number,
  purchases: number,
  types: GoodTypeServer[],
  categories: GoodCategoryServer[],
  genres: GoodGenreServer[],
  graphics: GraphicTypeServer[],
  platforms: PlatformTypeServer[],
  stacks: GoodStackServer[],
  compositions: ContentListServer[],
  images: ImageServer[],
}

export type PurchasedGoods = Good & {
  purchaseCost: number,
  purchaseDate: string,
  linkSpec: string,
}

