import React, {ReactElement} from 'react';
import {useAppDispatch} from '../../../../hooks';
import {toggleFilterPlatform} from '../../../../store/actions';
import { ReactComponent as IconGame } from '../../../../sprite/icon-game.svg';
import {PlatformName, PlatformType} from '../../../../types/goods';

type CheapPlatformProps = {
  platform: PlatformType;
}

export default function CheapPlatform({platform}: CheapPlatformProps): ReactElement {
  const dispatch = useAppDispatch();

  return (
    <li
      onClick={() => dispatch(toggleFilterPlatform(platform))}
    >
      <p
        className="cheap cheap_view--accent-tertiary cheap_view--accent-tertiary-hover text_size--12 cheap_size--s"
      >
        <IconGame className="icon" width={16} height={16}/>
        <span>{PlatformName[platform]}</span>
      </p>
    </li>
  );
}
