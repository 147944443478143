import React, {ReactElement} from 'react';
import Header from '../../components/page/header/header';
import Breadcrumbs from '../../components/page/breadcrumbs/breadcrumbs';
import Footer from '../../components/page/footer/footer';
import ModalContainer from '../../components/page/modal-container/modal-container';
import Cart from '../../components/catalog/cart/cart';

export default function CartScreen(): ReactElement {
  return (
    <>
      <Header />
      <Breadcrumbs
        firstPage={'Корзина'}
      />
      <Cart/>
      <Footer/>
      <ModalContainer />
    </>
  );
}
