import React, {ReactElement, useEffect, useState} from 'react';
import Breadcrumbs from '../page/breadcrumbs/breadcrumbs';
import ArticleCard from './article-card/article-card';
import {fetchArticlesCardsAction} from '../../store/api-actions';
import {useAppDispatch, useAppSelector} from '../../hooks';
import Pagination from '../page/pagination/pagination';
import {scrollTop} from '../../utils/common';
import {useSearchParams} from 'react-router-dom';

const ARTICLES_PER_PAGE = 12;

export default function Blog(): ReactElement {
  const dispatch = useAppDispatch();

  let [searchParams, setSearchParams] = useSearchParams();

  const urlPageParam = searchParams.get('page');

  const [currentPage, setCurrentPage] = useState(urlPageParam ? Number(urlPageParam) : 1);
  const articleCards = useAppSelector((state) => state.articleCards);
  const articleCount = useAppSelector((state) => state.articlesCount);
  const pageCount = Math.ceil(articleCount / ARTICLES_PER_PAGE);

  useEffect(() => {
    dispatch(fetchArticlesCardsAction({perPage: ARTICLES_PER_PAGE, currentPage}));

    const urlParams = new URLSearchParams;

    if (currentPage !== 1) {
      urlParams.set('page', String(currentPage));
    }

    setSearchParams(urlParams);

    scrollTop();
  }, [currentPage]);

  return (
    <main className="blog__main-page">
      <Breadcrumbs
        firstPage={'Блог'}
      />
      <h1 className="blog__title title title_size--l container">
        Блог
      </h1>
      <section className="blog container">
        <div className="blog__list space_bottom-xl grid grid_col--3 grid_gap--s-m-m">
          {articleCards.map((article) => (
            <ArticleCard
              title={article.title}
              date={article.date}
              view={article.view}
              preview={article.preview}
              slug={article.slug}
              key={article.id}
            ></ArticleCard>
          ))}
        </div>

        <Pagination
          pageCount={pageCount}
          currentPage={currentPage}
          setCurrentPage={(page) => setCurrentPage(page)}
        />
      </section>
    </main>
  );
}
