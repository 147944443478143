import React, {ReactElement} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {FormProvider, useForm} from 'react-hook-form';
import {showModal} from '../../store/actions';
import {ValidationConfig} from '../../const/validation-config';
import Input from '../atomas/input/input';
import InputPassword from '../atomas/input-password/input-password';
import FormSubmitButton from '../atomas/form-submit-button/form-submit-button';
import {ModalType} from '../../types/modalType';
import {EditProfileData} from '../../types/data';
import {editProfileAction} from '../../store/api-actions';
import {toast} from 'react-toastify';

const FORM_NAME = 'update';

export default function Profile(): ReactElement {
  const methods = useForm<EditProfileData>();
  const dispatch = useAppDispatch();

  const userData = useAppSelector((state) => state.user);

  const onSubmit = methods.handleSubmit(data => {
    if (data.email === userData?.email && data.name === userData?.name) {
      toast('Вы ничего не изменили', {type: 'warning'});

      return;
    }
    if (data.email === userData?.email) {
      delete data.email;
    }

    dispatch(editProfileAction(data));
  });

  return (
    <main className="profile__main-page">
      <h1 className="profile__title title title_size--l container">Профиль</h1>

      <section className="profile__setting-wrapper container">
        <h2 className="visuallyhidden">Настройки профиля</h2>

        <FormProvider {...methods}>
          <form
            className="profile__setting"
            spellCheck="false"
            noValidate
            onSubmit={event => event.preventDefault()}
          >
            <Input
              formName={FORM_NAME}
              name={'name'}
              label={'Имя'}
              placeholder={'Введите ваше имя'}
              type={'text'}
              validation={ValidationConfig.name}
              value={userData?.name}
            />

            <Input
              formName={FORM_NAME}
              name={'email'}
              label={'Email'}
              placeholder={'Введите ваш Email'}
              type={'email'}
              validation={ValidationConfig.email}
              value={userData?.email}
            />

            <div className="profile__password space_bottom-l">
              <InputPassword
                formName={FORM_NAME}
                name={'current_password'}
                label={'Пароль'}
                placeholder={'Введите пароль'}
                validation={ValidationConfig.password}
                className={'profile__password-field-wrapper'}
              />
              <button
                className="profile__password-update-button button button_view--tertiary button_size--s text_align--center button_size--full"
                type="button"
                onClick={() => dispatch(showModal(ModalType.ModalEditPassword))}
              >
                Изменить пароль
              </button>
            </div>

            <FormSubmitButton onSubmit={onSubmit}>
              Сохранить
            </FormSubmitButton>
          </form>
        </FormProvider>
      </section>
    </main>
  );
}
