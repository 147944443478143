import React, {ReactElement} from 'react';

import {ReactComponent as IconEye} from '../../../sprite/icon-eye.svg';
import {ReactComponent as IconCart} from '../../../sprite/icon-cart.svg';
import {GraphicType, Image, PlatformType} from '../../../types/goods';
import CheapGenre from '../cheap/cheap-genre/cheap-genre';
import CheapGraphic from '../cheap/cheap-graphic/cheap-graphic';
import CheapPlatform from '../cheap/cheap-platform/cheap-platform';
import {Link} from 'react-router-dom';
import {convertTriads} from '../../../utils/common';
import cn from 'classnames';

type GoodsCardProps = {
  title: string,
  id: string,
  view: number,
  purchases: number,
  graphic?: GraphicType,
  genre: string,
  nameGenre: string,
  platform?: PlatformType,
  price: number,
  oldPrice: number,
  images: Image[],
  className?: string,
};

export default function GoodsCard({title, id, view, purchases, graphic, genre, nameGenre, platform, price, oldPrice, images, className}: GoodsCardProps): ReactElement {
  return (
    <li
        data-goods-id={id}
        className={cn(
          'goods-card',
          className,
        )}>
      <Link
        to={`/products/${id}`}
      >
        <picture>
          <img
            className={'goods-card__img'}
            src={images[0].src}
            alt={images[0].alt}
            width={329}
            height={466}
            loading='lazy'
          />
        </picture>
      </Link>
      <div className="goods-card__inner">
        <ul className="cheap__list space_bottom-s">
          <li>
            <Link
              to={`/products/${id}`}
              className="cheap cheap_view--bordered cheap_view--bordered-hover text_size--12 cheap_size--s link link_view--primary"
            >
              <IconEye className="icon" width={16} height={16}/>
              <span className="cheap__count show--after-mobile goods-card__view">
                {view}
              </span>
              <span>
                Просмотров
              </span>
            </Link>
          </li>
          <li>
            <Link
              to={`/products/${id}`}
              className="cheap cheap_view--bordered cheap_view--bordered-hover text_size--12 cheap_size--s link link_view--primary"
            >
              <IconCart className="icon" width={16} height={16}/>
              <span className="cheap__count show--after-mobile goods-card__purchases">
                {purchases}
              </span>
              <span>
                Купили
              </span>
            </Link>
          </li>
        </ul>
        <Link
          className="link_view--primary space_bottom-m"
          to={`/products/${id}`}
        >
          <h3 className="goods-card__title text_size--14-16-16 text_line-height--150 text_view--base">
            {title}
          </h3>
        </Link>
        <ul className="cheap__list space_bottom-m">
          {graphic && <CheapGraphic
            graphic={graphic}
          />}
          {<CheapGenre
            caption={nameGenre}
            genre={genre}
          />}
          {platform && <CheapPlatform
            platform={platform}
          />}
        </ul>
        <div className="goods-card__inner-bottom">
          <div className="goods-card__prices-wrap">
            <p className="goods-card__prices-caption text_size--14 text_view--tertiary">
              Цена:
            </p>
            <p className="goods-card__prices">
              <span className="text_size--20 text_line-height--100 text_weight--500 text_view--accent">
                {convertTriads(price)} ₽
              </span>
              <del className="goods-card__prices-old text_size--14 text_view--tertiary">
                {convertTriads(oldPrice)} ₽
              </del>
            </p>
          </div>
          <Link
            to={`/products/${id}`}
            className="button_view--simple goods-card__button button button_view--primary button_size--s"
          >
            Подробнее
          </Link>
        </div>
      </div>
    </li>
  );
}
