import React, {ReactElement} from 'react';

type PictureProps = {
  src: string;
  extension?: string;
  alt: string;
  lazy: boolean;
  width: number;
  height: number;
  className: string;
}

export default function Picture({src, alt, lazy, height, width, className, extension = 'png'}: PictureProps): ReactElement {
  return (
    <picture>
      <source
        srcSet={`${src}@1x.webp 1x, ${src}@2x.webp 2x`}
        type="image/webp"
      />
      <source
        srcSet={`${src}@1x.${extension} 1x, ${src}@2x.${extension} 2x`}
        type="image/jpeg"
      />
      <img
        className={className}
        src={`${src}@1x.${extension}`}
        alt={alt}
        width={width}
        height={height}
        loading={lazy ? 'lazy' : 'eager'}
      />
    </picture>
  );
}
