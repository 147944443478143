import React, {ReactElement} from 'react';
import Modal from '../modal';
import InputPassword from '../../atomas/input-password/input-password';
import {useAppDispatch} from '../../../hooks';
import {RegistrationData} from '../../../types/data';
import {registrationAction} from '../../../store/api-actions';
import FormTitle from '../../atomas/form-title/form-title';
import {ValidationConfig} from '../../../const/validation-config';
import Input from '../../atomas/input/input';
import {FormProvider, useForm} from 'react-hook-form';
import {hideModal} from '../../../store/actions';
import FormCloseButton from '../../atomas/form-close-button/form-close-button';
import FormSubmitButton from '../../atomas/form-submit-button/form-submit-button';
import {AppRoute} from '../../../const/common';
import {Link} from 'react-router-dom';
import InputCheckboxConfirm from '../../atomas/input-checkbox-confirm/input-checkbox-confirm';

const FORM_NAME = 'login';

export default function ModalRegistration(): ReactElement {
  const methods = useForm<RegistrationData>();
  const dispatch = useAppDispatch();

  const onSubmit = methods.handleSubmit(data => {
    dispatch(registrationAction(data));
  });

  return (
    <Modal>
      <FormProvider {...methods}>
        <form
          className="modal__inner"
          spellCheck="false"
          noValidate
          onSubmit={event => event.preventDefault()}
        >
          <FormTitle>Регистрация</FormTitle>

          <Input
            formName={FORM_NAME}
            name={'name'}
            label={'Имя'}
            placeholder={'Введите ваше имя'}
            type={'text'}
            validation={ValidationConfig.name}
          />

          <Input
            formName={FORM_NAME}
            name={'email'}
            label={'Email'}
            placeholder={'Введите ваш Email'}
            type={'email'}
            validation={ValidationConfig.email}
          />

          <InputPassword
            formName={FORM_NAME}
            name={'password'}
            label={'Пароль'}
            placeholder={'Введите ваш пароль'}
            validation={ValidationConfig.password}
          />

          <InputPassword
            formName={FORM_NAME}
            name={'password_confirmation'}
            label={'Повторите пароль'}
            placeholder={'Введите ваш пароль'}
            validation={ValidationConfig.password}
            isConfirmPassword={true}
          />

          <InputCheckboxConfirm
            formName={FORM_NAME}
            name={'confirm'}
            validation={ValidationConfig.confirm}
          >
            Я принимаю {' '}
            <Link
              to={AppRoute.Privacy}
              onClick={() => dispatch(hideModal())}
              className="link link_color--accent"
            >
              условия пользовательского соглашения
            </Link>
          </InputCheckboxConfirm>

          <FormSubmitButton onSubmit={onSubmit}>
            Создать аккаунт
          </FormSubmitButton>

          <FormCloseButton onClick={() => dispatch(hideModal())}/>
        </form>
      </FormProvider>
    </Modal>
  );
}
