import React, {ReactElement} from 'react';
import {toast} from 'react-toastify';
import {useCopyToClipboard} from 'usehooks-ts';
import {ReactComponent as IconPhone} from '../../sprite/icon-phone.svg';
import {ReactComponent as IconEmail} from '../../sprite/icon-email.svg';
import {ReactComponent as IconTimer} from '../../sprite/icon-timer.svg';
import {ReactComponent as IconHome} from '../../sprite/icon-home.svg';
import {FormProvider, useForm} from 'react-hook-form';
import {EditProfileData} from '../../types/data';
import {useAppDispatch, useAppSelector} from '../../hooks';
import Input from '../atomas/input/input';
import {ValidationConfig} from '../../const/validation-config';
import FormSubmitButton from '../atomas/form-submit-button/form-submit-button';
import Textarea from '../atomas/textarea/textarea';
import Breadcrumbs from '../page/breadcrumbs/breadcrumbs';

const FORM_NAME = 'contacts';

export default function Contacts(): ReactElement {
  const [copiedText, copy] = useCopyToClipboard();

  const handleCopy = (text: string) => () => {
    copy(text)
      .then(() => {
        toast('Email скопирован', {type: 'success'});
      })
      .catch(error => {
        toast('Попробуйте снова', {type: 'warning'});
      })
  }

  const methods = useForm<EditProfileData>();
  const dispatch = useAppDispatch();

  const userData = useAppSelector((state) => state.user);

  const onSubmit = methods.handleSubmit(data => {
    toast('Форма обратной связи в разработке. Вы можете написать нам на Email', {type: 'warning'});
  });

  return (
    <>
      <main className="contacts">
        <Breadcrumbs
          firstPage={'Контакты'}
        />

        <h1 className="contacts__title title title_size--l container">Контакты</h1>

        <section className="grid grid_gap--xxs-xs-xs space_bottom-5xl">
          {/*<YMaps>*/}
          {/*  <div>*/}
          {/*    <Map defaultState={{ center: [55.75, 37.57], zoom: 9 }} />*/}
          {/*  </div>*/}
          {/*</YMaps>*/}

          <div style={{ position: "relative", overflow: "hidden" }}>
            <a
              href="https://yandex.com/maps/213/moscow/?utm_medium=mapframe&utm_source=maps"
              style={{ color: "#eee", fontSize: 12, position: "absolute", top: 0 }}
            >
              Москва
            </a>
            <a
              href="https://yandex.com/maps/213/moscow/house/derbenevskaya_naberezhnaya_7s22/Z04YcAJhTEABQFtvfXtzc31mYw==/?ll=37.651556%2C55.722157&utm_medium=mapframe&utm_source=maps&z=17.11"
              style={{ color: "#eee", fontSize: 12, position: "absolute", top: 14 }}
            >
              Яндекс Карты — транспорт, навигация, поиск мест
            </a>
            <iframe
              src="https://yandex.com/map-widget/v1/?ll=37.651556%2C55.722157&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1NjY4OTMwNxJQ0KDQvtGB0YHQuNGPLCDQnNC-0YHQutCy0LAsINCU0LXRgNCx0LXQvdC10LLRgdC60LDRjyDQvdCw0LHQtdGA0LXQttC90LDRjywgN9GBMjIiCg0ymxZCFX7jXkI%2C&z=17.11"
              width={560}
              height={400}
              frameBorder={1}
              allowFullScreen={true}
              style={{
                position: "relative",
                width: '100%',
              }}
            />
          </div>

          <div className="contacts__content container grid grid_gap--s-m-m">
            {/*<FormProvider {...methods}>*/}
            {/*  <form*/}
            {/*    className="contacts__form"*/}
            {/*    spellCheck="false"*/}
            {/*    noValidate*/}
            {/*    onSubmit={event => event.preventDefault()}*/}
            {/*  >*/}
            {/*    <h2 className="title title_size--m space_bottom-l">*/}
            {/*      Мы готовы вам помочь*/}
            {/*    </h2>*/}

            {/*    <p className="contacts__form-desc text text_weight--500 space_bottom-l">*/}
            {/*      Свяжитесь с нами для консультации или заказа ТЗ*/}
            {/*    </p>*/}

            {/*    <Input*/}
            {/*      formName={FORM_NAME}*/}
            {/*      name={'name'}*/}
            {/*      label={'Имя'}*/}
            {/*      placeholder={'Введите ваше имя'}*/}
            {/*      type={'text'}*/}
            {/*      validation={ValidationConfig.name}*/}
            {/*      value={userData?.name}*/}
            {/*    />*/}

            {/*    <Input*/}
            {/*      formName={FORM_NAME}*/}
            {/*      name={'email'}*/}
            {/*      label={'Email'}*/}
            {/*      placeholder={'Введите ваш Email'}*/}
            {/*      type={'email'}*/}
            {/*      validation={ValidationConfig.email}*/}
            {/*      value={userData?.email}*/}
            {/*    />*/}

            {/*    <Textarea*/}
            {/*      formName={FORM_NAME}*/}
            {/*      name={'message'}*/}
            {/*      label={'Ваше сообщение'}*/}
            {/*      placeholder={'Сообщение'}*/}
            {/*    />*/}

            {/*    <FormSubmitButton onSubmit={onSubmit}>*/}
            {/*      Отправить*/}
            {/*    </FormSubmitButton>*/}
            {/*  </form>*/}
            {/*</FormProvider>*/}

            <div className="contacts__info">
              <button
                className="button button_view--bordered button_size--s"
                onClick={handleCopy('info@it-tz.ru')}
              >
                <IconEmail className="icon" width={20} height={20}/>
                <span>info@it-tz.ru</span>
              </button>

              <span
                className="button button_view--bordered button_size--s"
              >
                <IconTimer className="icon" width={20} height={20}/>
                <span>График работы: С 9:00 до 18:00</span>
              </span>

              <span
                className="button button_view--bordered button_size--s"
              >
                <IconHome className="icon" width={20} height={20}/>
                <span>Адрес:  Россия, Москва, ул. Ленина д.3 к.2</span>
              </span>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
