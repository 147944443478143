import {AppRoute} from './common';

const BASE_URL = 'https://it-tz.ru';

export const ExternalLinks = {
  Telegram: '/#',
  WhatsApp: '/#',

  InfoEmail: 'mailto:info@it-tz.ru',
}

export const PageTitle = {
  Main: 'TaskStore | Готовые технические задания для IT-проектов, ТЗ на разработку сайта',
  Catalog: 'TaskStore | Каталог',
  Error: 'Страница не найдена',
  Privacy: 'TaskStore | Политика конфиденциальности',
  Profile: 'TaskStore | Профиль',
  PurchaseHistory: 'TaskStore | История покупок',
  Search: 'TaskStore | Результаты поиска',
  Blog: 'TaskStore | Статьи о разработке готовых ТЗ: составление и внедрение в проект',
  Article: 'TaskStore | Статья',
  Faq: 'TaskStore | Вопросы и ответы о разработке и составлении готовых технических заданий (ТЗ)',
  Contacts: 'TaskStore | Большой каталог готовых ТЗ для IT-сферы по выгодным ценам',
  About: 'TaskStore | Узнайте больше о нас - Интернет-магазин готовых ТЗ | TaskStore',
}
export const PageDesc = {
  Main: 'TaskStore - это интернет-магазин с каталогом готовых технических заданий для любой IT-сферы. Подробные ТЗ на разработку ПО, для сайтов и других IT проектов',
  Blog: 'Читайте интересные статьи о составлении готовых технических заданий для программистов и веб-разработчиков. Узнайте советы и рекомендации от наших экспертов, а также найдите для себя подробные инструкции ',
  Contacts: 'Хотите задать вопрос или оставить отзыв? Свяжитесь с нами прямо сейчас, заполнив удобную форму! Мы готовы помочь в выборе и покупке ТЗ в IT сфере. Быстрая обратная связь со службой поддержки',
  Faq: 'На этой странице вы найдете ответы на распространенные вопросы о процессе создания и составления индивидуальных ТЗ в IT сфере. Погрузитесь в нашу базу знаний и узнайте ответы, как наши эксперты составляют готовые технические задания',
  About: 'TaskStore - это команда экспертов в IT, готовая предоставить вам высококачественные готовые ТЗ для вашего проекта. Специализируемся на создании и продаже индивидуальных технических заданий для IT проектов',
}

export const CanonicalUrl = {
  Main: `${BASE_URL}${AppRoute.Root}`,
  Privacy: `${BASE_URL}${AppRoute.Privacy}`,
  Profile: `${BASE_URL}${AppRoute.Profile}`,
  PurchaseHistory: `${BASE_URL}${AppRoute.PurchaseHistory}`,
  Blog: `${BASE_URL}${AppRoute.Blog}`,
  Search: `${BASE_URL}${AppRoute.Search}`,
  Contacts: `${BASE_URL}${AppRoute.Contacts}`,
  Faq: `${BASE_URL}${AppRoute.Faq}`,
  About: `${BASE_URL}${AppRoute.About}`,
};
