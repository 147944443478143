import {DateType, parseDate} from '../../../utils/date';

import {ReactComponent as IconEye} from '../../../sprite/icon-eye.svg';
import {Link} from 'react-router-dom';

type ArticleCardProps = {
  title: string,
  date: string,
  view: number,
  preview: string,
  slug: string,
};

export default function ArticleCard({title, date, view, preview, slug}: ArticleCardProps) {
  return (
    <div className="article-card">
      <Link
        to={`/blog/${slug}`}
      >
        <img src={preview} alt={title} width={350} height={260} className="article-card__img" />
      </Link>

      <div className="article-card__info text_view--tertiary">
        <time className="article-card__info-date" dateTime={date}>
          {parseDate(date, DateType.MonthName)}
        </time>

        <p className="article-card__info-view">
          <IconEye className="icon" width={16} height={16}/>

          <span>
            {view}
          </span>
        </p>
      </div>

      <h3 className="article-card__title text_size--18 text_weight--500 text_line-height--125 text_view--base">
        <Link
          to={`/blog/${slug}`}
          className="link link_view--primary link_view--hover-opacity"
        >
          {title}
        </Link>
      </h3>
    </div>
  );
}
