import {ReactElement} from 'react';
import {useAppSelector} from '../../../hooks';
import {ModalType} from '../../../types/modalType';
import ModalLogin from '../../modal/modal-login/modal-login';
import ModalRegistration from '../../modal/modal-registration/modal-registration';
import ModalConfirmPurchase from '../../modal/modal-confirm-purchase/modal-confirm-purchase';
import ModalExit from '../../modal/modal-exit/modal-exit';
import ModalSendCode from '../../modal/modal-send-code/modal-send-code';
import ModalResetPassword from '../../modal/modal-reset-password/modal-reset-password';
import ModalResetPasswordConfirm from '../../modal/modal-reset-password-confirm/modal-reset-password-confirm';
import ModalEditPassword from '../../modal/modal-edit-password/modal-edit-password';

const getModalNode = (modal: ModalType | null): ReactElement => {
  switch (modal) {
  case null:
    return <></>;
  case ModalType.Login:
    return <ModalLogin/>
  case ModalType.Registration:
    return <ModalRegistration/>
  case ModalType.ConfirmPurchase:
    return <ModalConfirmPurchase/>
  case ModalType.SendCode:
    return <ModalSendCode/>
  case ModalType.ResetPassword:
    return <ModalResetPassword/>
  case ModalType.Exit:
    return <ModalExit/>
  case ModalType.ResetPasswordConfirm:
    return <ModalResetPasswordConfirm/>
  case ModalType.ModalEditPassword:
    return <ModalEditPassword/>
  default:
    return <></>
  }
}

export default function ModalContainer(): ReactElement {
  const modal = useAppSelector((state) => state.modal);

  const modalNode = getModalNode(modal);

  return (
    <>
      {modalNode}
    </>
  );
}
