import React, {ReactElement} from 'react';
import {ReactComponent as IconClick} from '../../../sprite/icon-click.svg';
import {ReactComponent as IconCalendar} from '../../../sprite/icon-calendar-2.svg';
import {ReactComponent as IconPage} from '../../../sprite/icon-page.svg';
import {ReactComponent as IconImage} from '../../../sprite/icon-image.svg';
import {ReactComponent as IconTool} from '../../../sprite/icon-tool.svg';
import {ReactComponent as IconCoin} from '../../../sprite/icon-coin.svg';

export default function DevCompound(): ReactElement {
  return (
    <>
      <section className="about__features space_bottom-5xl">
        <h2 className="goods-slider__title title title_size--m space_bottom-l">
          Что входит в ТЗ для сайта и мобильного приложения
        </h2>

        <hr className="top-category__line space_bottom-l"/>

        <ul className="grid grid_gap--s-m-m">
          <li className="top-category__item">
            <div
              className="top-category__link"
            >
              <IconClick className="icon icon_color--accent" width={34} height={34}/>
              <h3 className="text_line-height--125 text_weight--500">
                Описание проекта и цели
              </h3>

              <p className="text_size--14 text_line-height--150">
                Для начала важно определиться с назначением, целевой аудиторией, основными функциями и задачами проекта. В этом может помочь бриф с ответами клиента. Важно четко определить, какие проблемы должен решать сайт или мобильное приложение, и что может выделить его среди конкурентов.
              </p>
            </div>
          </li>
          <li className="top-category__item">
            <div
              className="top-category__link"
            >
              <IconPage className="icon icon_color--accent" width={34} height={34}/>
              <p className="text_line-height--125 text_weight--500">
                Функциональные требования
              </p>

              <p className="text_size--14 text_line-height--150">
                В этом разделе технического задания определяются функции, который должен выполнять IT-проект. При разработке сайта сюда входят требования к структуре страниц, навигации, интеграции с внешними сервисами (например, платежными системами или CRM), а также функционал административной панели.

                <br/>
                <br/>

                Для мобильного приложения это могут быть push-уведомления, работа в оффлайн-режиме, интеграция с социальными сетями. Для игры — уровни сложности, игровая механика, мультиплеер и другие специфические особенности.
              </p>
            </div>
          </li>
          <li className="top-category__item">
            <div
              className="top-category__link"
            >
              <IconImage className="icon icon_color--accent" width={34} height={34}/>
              <p className="text_line-height--125 text_weight--500">
                Дизайн и пользовательский интерфейс
              </p>

              <p className="text_size--14 text_line-height--150">
                В данном разделе описываются требования к интерфейсу и графическому оформлению. В случае с игрой сюда также включается описание персонажей, локаций, анимации и других визуальных элементов.
              </p>
            </div>
          </li>
          <li className="top-category__item">
            <div
              className="top-category__link"
            >
              <IconTool className="icon icon_color--accent" width={34} height={34}/>
              <p className="text_line-height--125 text_weight--500">
                Технические требования
              </p>

              <p className="text_size--14 text_line-height--150">
                Технические требования в ТЗ на разработку сайта охватывают вопросы, связанные с хостингом, доменным именем, используемыми технологиями и фреймворками. Также сюда входят требования к безопасности, оптимизации скорости загрузки и SEO.

                <br/>
                <br/>

                Для разработки мобильного приложения включают выбор платформы (iOS, Android), используемые технологии и фреймворки, требования к производительности и совместимости с различными устройствами. Также важно учитывать требования к безопасности данных пользователей и оптимизации работы.
              </p>
            </div>
          </li>
          <li className="top-category__item">
            <div
              className="top-category__link"
            >
              <IconCalendar className="icon icon_color--accent" width={34} height={34}/>
              <p className="text_line-height--125 text_weight--500">
                План работ и сроки
              </p>

              <p className="text_size--14 text_line-height--150">
                Этот блок технического задания представляет детализированный план работ, разбитый на этапы, с указанием сроков выполнения каждого этапа. Это позволяет четко контролировать процесс и своевременно выявлять отклонения от графика.
              </p>
            </div>
          </li>
          <li className="top-category__item">
            <div
              className="top-category__link"
            >
              <IconCoin className="icon icon_color--accent" width={34} height={34}/>
              <p className="text_line-height--125 text_weight--500">
                Бюджет
              </p>

              <p className="text_size--14 text_line-height--150">
                Здесь указывается общий бюджет проекта и распределение средств по этапам, в котором могут быть предусмотрены расходы на маркетинг и продвижение, а также резервный фонд на случай непредвиденных затрат.

                <br/>
                <br/>

                Имея техническое задание на создание IT-продукта, разработчики могут более эффективно и качественно выполнять свою работу, а заказчики — быть уверенными в том, что конечный результат будет соответствовать их ожиданиям и требованиям.
              </p>
            </div>
          </li>
        </ul>
      </section>
    </>
  )
}
