import React, {ReactElement, useEffect} from 'react';
import Header from '../../components/page/header/header';
import Footer from '../../components/page/footer/footer';
import Promo from '../../components/promo/promo';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import ModalContainer from '../../components/page/modal-container/modal-container';
import {Helmet} from 'react-helmet-async';
import {CanonicalUrl, PageDesc, PageTitle} from '../../const/seo';
import {showModal} from '../../store/actions';
import {ModalType} from '../../types/modalType';
import {useAppDispatch} from '../../hooks';
import {AppRoute} from '../../const/common';
import TopCategory from '../../components/main-page/top-category/top-category';
import HitGoods from '../../components/main-page/hit-goods/hit-goods';
import NewGoods from '../../components/main-page/new-goods/new-goods';
import Seo from '../../components/service/seo/seo';
import DevCompound from '../../components/main-page/dev-compound/dev-compound';

export default function MainScreen(): ReactElement {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const hash = location.hash;

  useEffect(() => {
    switch (hash) {
      case '#registration':
        navigate(AppRoute.Root);
        dispatch(showModal(ModalType.Registration));

        break;
    }
  }, [hash]);

  return (
    <>
      <Seo
        title={PageTitle.Main}
        url={CanonicalUrl.Main}
        desc={PageDesc.Main}
      />

      <Header />

      <Promo />

      <main className="container">
        <TopCategory/>
        <HitGoods/>
        <NewGoods/>
        <DevCompound/>
      </main>

      <Footer />
      <ModalContainer />
    </>
  );
}
