import React, {ReactElement} from 'react';

import Modal from '../modal';
import {useAppDispatch} from '../../../hooks';
import {hideModal, showModal} from '../../../store/actions';
import {ModalType} from '../../../types/modalType';
import Input from '../../atomas/input/input';
import {FormProvider, useForm} from 'react-hook-form';
import FormCloseButton from '../../atomas/form-close-button/form-close-button';
import FormSubmitButton from '../../atomas/form-submit-button/form-submit-button';
import FormTitle from '../../atomas/form-title/form-title';
import {ValidationConfig} from '../../../const/validation-config';
import InputPassword from '../../atomas/input-password/input-password';
import {loginAction} from '../../../store/api-actions';
import {AuthData} from '../../../types/data';

const FORM_NAME = 'login';

export default function ModalLogin(): ReactElement {
  const methods = useForm<AuthData>();
  const dispatch = useAppDispatch();

  const onSubmit = methods.handleSubmit(data => {
    dispatch(loginAction(data));
  });

  return (
    <Modal>
      <FormProvider {...methods}>
        <form
          className="modal__inner"
          spellCheck="false"
          noValidate
          onSubmit={event => event.preventDefault()}
        >
          <FormTitle>Войти</FormTitle>

          <Input
            formName={FORM_NAME}
            name={'email'}
            label={'Email'}
            placeholder={'Введите Email'}
            type={'email'}
            validation={ValidationConfig.email}
          />

          <InputPassword
            formName={FORM_NAME}
            name={'password'}
            label={'Пароль'}
            placeholder={'Введите пароль'}
            validation={ValidationConfig.password}
          />

          <button
            className="button button_view--link button_view--link-underline text_view--accent space_bottom-s text_size--14 text_line-height--140"
            type="button"
            onClick={() => dispatch(showModal(ModalType.SendCode))}
          >
            Забыли пароль?
          </button>

          <FormSubmitButton onSubmit={onSubmit} className={'space_bottom-s'}>
            Войти
          </FormSubmitButton>

          <p className="input__wrapper text_size--14 text_line-height--140 text_align--center modal__bottom">
            <span>Нет аккаунта?</span>
            {' '}
            <button
              className="button button_view--link button_view--link-underline text_view--accent space_bottom-s"
              type="button"
              onClick={() => dispatch(showModal(ModalType.Registration))}
            >
              Зарегистрируйтесь
            </button>
          </p>

          <FormCloseButton onClick={() => dispatch(hideModal())}/>
        </form>
      </FormProvider>
    </Modal>
  )
}
