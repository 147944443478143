import React, {ReactElement, useEffect, useState} from 'react';
import {Image} from '../../../types/goods';
import cn from 'classnames';

type GoodsCardSliderProps = {
  images: Image[];
  prefix: string;
}

export default function GoodsCardSlider({images, prefix}: GoodsCardSliderProps): ReactElement {
  const [sliderStep, setSliderStep] = useState(1);
  const sliderSteps = images.length;

  useEffect(() => {
    const id = setTimeout(() => {
      setSliderStep((sliderStep) % sliderSteps + 1);
    }, 4000)

    return () => clearTimeout(id);
  });

  return (
    <>
      <picture>
        <img
          className={`${prefix}__gallery-item-image`}
          src={images[sliderStep - 1].src}
          alt={images[sliderStep - 1].alt}
          width={329}
          height={466}
          loading='lazy'
        />
      </picture>
      <ul className={`${prefix}__gallery`}>
        {images.map((image, index) => (
          <li
            className={cn(
              `${prefix}__gallery-item`,
              {[`${prefix}__gallery-item--active`]: sliderStep === index + 1}
            )}
            // className={`${prefix}__gallery-item`}
            onClick={() => setSliderStep(index + 1)}
            key={index}
          >
            <picture>
              <img
                className={`${prefix}__gallery-item-image`}
                src={image.src}
                alt={image.alt}
                width={329}
                height={466}
                loading='lazy'
              />
            </picture>
          </li>
        ))}
      </ul>
      <ul className={`${prefix}__slider-bullet-list`} data-goods-popup-slider={sliderStep}>
        {new Array(images.length).fill(0).map((item, index) => (
          <li
            className={`${prefix}__slider-bullet-item`}
            data-goods-popup-bullet={index + 1}
            key={index}
          >
            <span className="visuallyhidden">{index + 1}</span>
          </li>
        ))}
      </ul>
    </>
  );
}
