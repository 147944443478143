import {ReactElement} from 'react';

type PlugProps = {
  caption: string;
  children: ReactElement;
  className?: string;
}

export default function Plug({caption, children, className = ''}: PlugProps): ReactElement {
  return (
    <div
      className={`empty-plug__wrap ${className}`}
    >
      <div className="empty-plug">
        <img
          className="empty-plug__image space_bottom-m"
          src="/img/purchase-history-cart.svg"
          alt="Тележка для покупок"
        />
        <b className="title title_size--xxs text_view--tertiary text_align--center space_bottom-l">
          {caption}
        </b>
        {children}
      </div>
    </div>
  );
}
