import React, {ReactElement} from 'react';
import {useAppDispatch} from '../../../../hooks';
import {toggleFilterGenre} from '../../../../store/actions';
import { ReactComponent as IconPage } from '../../../../sprite/icon-page.svg';

type CheapGenreProps = {
  caption: string;
  genre: string;
}

export default function CheapGenre({caption, genre}: CheapGenreProps): ReactElement {
  const dispatch = useAppDispatch();

  return (
    <li
      onClick={() => dispatch(toggleFilterGenre({
        value: genre,
        name: caption,
      }))}
    >
      <p
        className="cheap cheap_view--accent-tertiary cheap_view--accent-tertiary-hover text_size--12 cheap_size--s"
      >
        <IconPage className="icon" width={16} height={16}/>
        <span>{caption}</span>
      </p>
    </li>
  );
}
