import React, {ReactElement, ReactNode, useEffect} from 'react';
import {enableScroll, preventScroll} from '../../utils/common';
import {hideModal} from '../../store/actions';
import {useAppDispatch} from '../../hooks';

type ModalProps = {
  children: ReactNode;
}

export default function Modal({children}: ModalProps): ReactElement {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    //   behavior: 'auto',
    // });

    preventScroll();

    return () => enableScroll();
  });

  const modalBackClickHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = event.target as Element;

    target.classList.contains('modal') && target.addEventListener('mouseup', (event) => {
      const target = event.target as Element;
      target.classList.contains('modal') && dispatch(hideModal());
    });
  }

  return (
    <article
      className="modal modal--open"
      onMouseDown={modalBackClickHandler}
    >
      {children}
    </article>
  );
}
