import {ReactElement} from 'react';

import {ReactComponent as IconArrowBottom} from '../../../sprite/icon-arrow-bottom.svg';
import {ReactComponent as IconSort} from '../../../sprite/icon-sort.svg';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import cn from 'classnames';
import {SortingType} from '../../../types/sorting';
import {setSorting} from '../../../store/actions';

const getCurrentText = (type: SortingType) => {
  switch (type) {
  case SortingType.Popular:
    return 'Популярные';
  case SortingType.DateOlder:
    return 'Старые';
  case SortingType.DateNewer:
    return 'Новые';
  case SortingType.PriceHigher:
    return 'Дорогие';
  case SortingType.PriceLower:
    return 'Дешёвые';
  }
}

export default function CatalogSorting(): ReactElement {
  const sortingType = useAppSelector((state) => state.sorting);

  const dispatch = useAppDispatch();

  return (
    <form className="catalog__sorting sorting">
      <p className="sorting__label text_view--tertiary text_size--14">
        Отображать:
      </p>
      <details
        className="select select--form text_size--14"
      >
        <summary className="select__current">
          <IconArrowBottom className="icon select__arrow" width={24} height={24}/>
          <span className="select__current-text">
            {getCurrentText(sortingType)}
          </span>
        </summary>
        <ul className="select__list">
          <li
            tabIndex={1}

            className={cn(
              'select__item',
              {'select__item--active': sortingType === SortingType.Popular}
            )}

            onClick={() => {
              dispatch(setSorting(SortingType.Popular))
            }}
          >
            <span className="select__item-caption">
              Популярные
            </span>
          </li>
          <li
            tabIndex={1}

            className={cn(
              'select__item',
              {'select__item--active': sortingType === SortingType.DateNewer || sortingType === SortingType.DateOlder},
              {'select__item--reverse': sortingType === SortingType.DateOlder},
            )}

            onClick={() => {
              switch (sortingType) {
              case SortingType.DateNewer:
                dispatch(setSorting(SortingType.DateOlder));
                break;
              case SortingType.DateOlder:
                dispatch(setSorting(SortingType.DateNewer));
                break;
              default:
                dispatch(setSorting(SortingType.DateNewer));
              }
            }}
          >
            <span className="select__item-caption">
              Дата загрузки
            </span>
            <span className="select__item-icon-direction">
              <IconSort className="icon" width={16} height={16}/>
            </span>
          </li>
          <li
            tabIndex={1}

            className={cn(
              'select__item',
              {'select__item--active': sortingType === SortingType.PriceLower || sortingType === SortingType.PriceHigher},
              {'select__item--reverse': sortingType === SortingType.PriceHigher},
            )}

            onClick={() => {
              switch (sortingType) {
                case SortingType.PriceLower:
                  dispatch(setSorting(SortingType.PriceHigher));
                  break;
                case SortingType.PriceHigher:
                  dispatch(setSorting(SortingType.PriceLower));
                  break;
                default:
                  dispatch(setSorting(SortingType.PriceLower));
              }
            }}
          >
            <span className="select__item-caption">
              Стоимость
            </span>
            <span className="select__item-icon-direction">
              <IconSort className="icon" width={16} height={16}/>
            </span>
          </li>
        </ul>
      </details>
    </form>
  );
}
