import React, {ReactElement} from 'react';
import PurchaseHistoryGoodsCard from '../../goods/purchase-history-goods-card/purchase-history-goods-card';
import {useAppSelector} from '../../../hooks';
import PlugReturn from '../../plug/plug-return/plug-return';
import {AppRoute} from '../../../const/common';
import Plug from '../../plug/plug';

export default function PurchaseHistoryList(): ReactElement {
  const purchasedGoods = useAppSelector((state) => state.purchasedGoods);

  return (
    <ul className="buy-goods__list">
      {purchasedGoods.map((good) => (
        <PurchaseHistoryGoodsCard
          desc={good.desc}
          fullDesc={good.fullDesc}
          graphic={good.graphic}
          nameGenre={good.nameGenre}
          platform={good.platform}
          images={good.images}
          stack={good.stack}
          style={good.style}
          pages={good.pages}
          priceDev={good.priceDev}
          isAdminPanel={good.isAdminPanel}
          isEstimate={good.isEstimate}
          contentList={good.contentList}
          analog={good.analog}
          purchaseDate={good.purchaseDate}
          purchaseCost={good.purchaseCost}
          linkSpec={good.linkSpec}
          id={good.id}
          view={good.view}
          purchases={good.purchases}
          fullGenre={good.fullGenre}
          type={good.type}
          typeSlug={good.typeSlug}
          category={good.category}
          genre={good.genre}
          price={good.price}
          oldPrice={good.oldPrice}
          date={good.date}
        />
      ))}

      {purchasedGoods.length === 0 && <Plug
        caption={'История покупок пуста'}
        className={'container'}
      >
        <PlugReturn
          caption={'Перейти в каталог'}
          link={AppRoute.Catalog}
        />
      </Plug>}
    </ul>
  );
}
