import React, {ReactElement} from 'react';
import GoodsCardSlider from '../goods-card-slider/goods-card-slider';
import {GoodType, GraphicName, PurchasedGoods} from '../../../types/goods';
import cn from 'classnames';
import {parseDate} from '../../../utils/date';

export default function PurchaseHistoryGoodsCard({type, desc, fullDesc, graphic, nameGenre, platform, images, stack, style, pages, priceDev, isAdminPanel, isEstimate, contentList, analog, purchaseDate, purchaseCost, linkSpec}: PurchasedGoods): ReactElement {
  return (
    <li className="buy-goods__item container">
      <article className="buy-goods" data-buy-goods-slider={5}>
        <div className="buy-goods__left-col">
          <GoodsCardSlider
            images={images}
            prefix={'buy-goods'}
          />
        </div>
        <div className="buy-goods__right-col">
          <h2 className="title title_size--xs space_bottom-l">
            {desc}
          </h2>
          <details className="buy-goods__spoiler space_bottom-l" open={true}>
            <summary className="buy-goods__spoiler-title text_size--16 text_line-height--150 text_weight--500 text_view--base space_bottom-m">
              Общее описание
              <svg className="icon" width={24} height={24}>
                <use xlinkHref="/img/sprite/sprite.svg#icon-arrow-bottom" />
              </svg>
            </summary>
            <div className="buy-goods__compress text_size--14 text_line-height--150">
              <p className="buy-goods__compress-text">
                {fullDesc}
              </p>
              <button
                className="buy-goods__compress-more button button_size--narrow text_view--accent"
                type="button"
              >
                Показать еще
                <svg className="icon" width={24} height={24}>
                  <use xlinkHref="/img/sprite/sprite.svg#icon-arrow-bottom" />
                </svg>
              </button>
            </div>
          </details>
          <details className="buy-goods__spoiler space_bottom-l" open={true}>
            <summary className="buy-goods__spoiler-title text_size--16 text_line-height--150 text_weight--500 text_view--base space_bottom-m">
              Информация
              <svg className="icon" width={24} height={24}>
                <use xlinkHref="/img/sprite/sprite.svg#icon-arrow-bottom" />
              </svg>
            </summary>
            <ul
              className={cn(
                'buy-goods__info',
                'buy-goods__info--two-col',
                'text_size--14',
                'text_line-height--140',
                {'buy-goods__info--two-col--even': type === GoodType.Site}
              )}
            >
              <li>
                <dl>
                  <dt>{type === GoodType.Game ? 'Жанр' : 'Вид'}</dt>
                  <dd>{nameGenre}</dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>Рекомендуемый стек технологий</dt>
                  <dd>{stack.join(', ')}</dd>
                </dl>
              </li>
              {graphic && <li>
                <dl>
                  <dt>Графика</dt>
                  <dd>{GraphicName[graphic]}</dd>
                </dl>
              </li>}
              {style && <li>
                <dl>
                  <dt>Стиль</dt>
                  <dd>{style}</dd>
                </dl>
              </li>}
              {platform && <li>
                <dl>
                  <dt>Платформа</dt>
                  <dd>{platform}</dd>
                </dl>
              </li>}
              <li>
                <dl>
                  <dt>Количество страниц</dt>
                  <dd>{pages}</dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>Стоимость разработки</dt>
                  <dd>{priceDev + '₽'}</dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>Административная панель</dt>
                  <dd>{isAdminPanel ? 'Да' : 'Нет'}</dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>Смета</dt>
                  <dd>{isEstimate ? 'Да' : 'Нет'}</dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>Аналоги</dt>
                  <dd>{analog}</dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>Состав заказа</dt>
                  <dd>
                    <ul>
                      {contentList.map((content) => (
                        <li>{content}</li>
                      ))}
                    </ul>
                  </dd>
                </dl>
              </li>
            </ul>
          </details>
          <div className="buy-goods__spoiler">
            <ul className="buy-goods__info buy-goods__info--three-col text_size--14 text_line-height--140">
              <li>
                <dl>
                  <dt>Стоимость покупки</dt>
                  <dd className="title title_size--s text_view--accent">{purchaseCost + ' ₽'}</dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>Дата покупки</dt>
                  <dd>{parseDate(purchaseDate)}</dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>Ссылка на тз:</dt>
                  <dd>
                    <a className="link link_color--accent" href="/products/0">
                      {linkSpec}
                    </a>
                  </dd>
                </dl>
              </li>
            </ul>
          </div>
        </div>
      </article>
    </li>
  );
}
