import React, {ReactElement} from 'react';
import Header from '../../components/page/header/header';
import Footer from '../../components/page/footer/footer';
import ModalContainer from '../../components/page/modal-container/modal-container';
import Contacts from '../../components/contacts/contacts';
import {CanonicalUrl, PageDesc, PageTitle} from '../../const/seo';
import Seo from '../../components/service/seo/seo';

export default function ContactsScreen(): ReactElement {
  return (
    <>
      <Seo
        title={PageTitle.Contacts}
        desc={PageDesc.Contacts}
        url={CanonicalUrl.Contacts}
      />

      <Header />
      <Contacts/>
      <Footer/>
      <ModalContainer />
    </>
  )
}
