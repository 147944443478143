import {ReactElement} from 'react';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {GraphicName, GraphicType} from '../../../types/goods';
import {toggleFilterGraphic} from '../../../store/actions';

type goodsGraphics = {
  [key in GraphicType]?: number;
}

export default function FilterGraphics(): ReactElement {
  const goods = useAppSelector((state) => state.goods);
  const filter = useAppSelector((state) => state.filter);

  const dispatch = useAppDispatch();

  const goodsGraphics: goodsGraphics = {};

  goods.forEach((good) => {
    if (!good.graphic) {
      return;
    }

    const graphic = good.graphic;

    const prevValue = goodsGraphics[graphic];

    if (prevValue) {
      goodsGraphics[graphic] = prevValue + 1;
    } else {
      goodsGraphics[graphic] = 1;
    }
  });

  return (
    <>
      {Object.values(GraphicType).map((graphic) => (
        <p className="input__wrapper input__wrapper--toggle" key={`graphic-${graphic}`}>
          <input
            className="checkbox"
            id={`graphic-${graphic}`}
            type="checkbox"
            defaultValue={`graphic-${graphic}`}
            onChange={() => {
              dispatch(toggleFilterGraphic(graphic));
            }}
            checked={filter.graphics.some((filterGraphic) => filterGraphic === graphic)}
          />
          <label htmlFor={`graphic-${graphic}`} aria-hidden="true" />
          <label
            htmlFor={`graphic-${graphic}`}
            className="input__description text_weight--400">
            {GraphicName[graphic] + ' '}
            <span className="filter__category-count text_view--tertiary">
              ({goodsGraphics[graphic] || 0})
            </span>
          </label>
        </p>

      ))}
    </>
  );
}
